import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import challengeOne from '../../assets/images/bbqguru/challenge-1.png';
import challengeTwo from '../../assets/images/bbqguru/challenge-2.png';
import bbqguruOneA from '../../assets/images/bbqguru/bbqguru1-a.png';
import bbqguruOneB from '../../assets/images/bbqguru/bbqguru1-b.png';
import bbqguruOneC from '../../assets/images/bbqguru/bbqguru1-c.png';
import bbqguruTwoA from '../../assets/images/bbqguru/bbqguru2-a.png';
import bbqguruTwoB from '../../assets/images/bbqguru/bbqguru2-b.png';
import bbqguruTwoC from '../../assets/images/bbqguru/bbqguru2-c.png';
import bbqGuru from '../../assets/images/bbqguru/bbqguru-banner.png';
import bookmark from '../../assets/images/bbqguru/bbqguru-bookmark.png';
import tinLogo from '../../assets/images/gamerize/tinLogo.png';

class BbqGuru extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <section >
          <img className="bbqguru-pic" src={bbqGuru} alt="banner" />
        </section>
        <section className="container bbqguru-section-portfolio">
          <div className="row py-md-6 mx-0 ">
            <div className="col-12">
              <div className="mini-heading">Case Study - App Development</div>
              <div className="heading-one">
                BBQ Guru
              </div>

              <div className="row para-mini pt-0">
                <div className="col-12 col-md-6 pl-0 pr-1">
                  <img className="icon" src={challengeOne} alt="" />
                  <div className="subheading">The Challenge</div>
                  <p className="desc">
                    An industrial client was struggling with the stability of a software product
                    critical to its key consumer offering
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeTwo} alt="" />
                  <div className="subheading">The Outcome</div>
                  <p className="desc">
                    Tintash reviewed the existing product, diagnosed its shortcomings and ported it
                    to a more stable offering
                  </p>
                </div>
              </div>
              <div className="subheading-big">The Project</div>
              <p className="description">
                Tintash relished the opportunity to partner with an established industrial player.
                BBQ Guru is a renowned provider of BBQ equipment and synonymous with family,
                community and convenience. The company had an associated app and hardware to
                regulate the BBQ for best performance.
              </p>
              <br />
              <p className="description">
                But the existing app had not been successful in providing the slick performance
                consumers expected. The biggest problem was that it was barely stable and frequently
                crashed. This was extremely annoying for consumers and the app had poor reviews and
                ratings.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-outer">
            <img className="subpic" src={bbqguruOneA} alt="bbqguru" />
            <img className="subpic" src={bbqguruOneB} alt="bbqguru" />
            <img className="subpic" src={bbqguruOneC} alt="bbqguru" />
          </div>
          <div className="img-title">BBQ Guru - In-App Screens</div>
        </section>
        <section className="container bbqguru-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="subheading-big">The Process</div>
              <p className="description">
                BBQ Guru reached out to Tintash among other agencies. Tintash took the company
                through a discovery phase and evaluated the existing code base. It became clear to
                us that the choice of React native as a cross-platform technology was not working in
                this case and shifting the apps to native iOS and Android would improve performance
                and stability. We used the discovery phase to carefully think through the options
                and proposed a budget and timeline to port the cross-platform app to native
                versions.
              </p>
              <br />
              <p className="description">
                Tintash realized early on that stability and consistency of wi-fi and data
                connections was critical to the success of the app. The first phase was thus defined
                to port the apps without any additional features with the clear goal of driving down
                crash rates to virtually zero. Over a two month period, a team of two iOS engineers
                and two Android engineers worked with a Project Manager to implement this plan.
                Tintash also involved hardware engineers and worked directly with the client
                hardware as part of the debugging process.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-outer">
            <img className="subpic" src={bbqguruTwoA} alt="bbqguru" />
            <img className="subpic" src={bbqguruTwoB} alt="bbqguru" />
            <img className="subpic" src={bbqguruTwoC} alt="bbqguru" />
          </div>
          <div className="img-title">BBQ Guru - In-App Screens</div>
        </section>
        <section className="container bbqguru-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                Timing was critical since the updated app needed to be launched in time for popular
                usage during spring and summer. Tintash not only delivered as per the expected
                timeline, there was a dramatic reduction in crash rates, down to virtually zero and
                this was reflected in much improved ratings and reviews. Ultimately, the project was
                successful because the problems in the existing app were correctly diagnosed and an
                efficient approach identified. This provided the client an opportunity to launch a
                stable and user-friendly app while buying time to think over additional features for
                upcoming iterations.
              </p>
            </div>
          </div>
        </section>

        <section className="container last-section-1">
          <div className="row">
            <div className="col-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{marginTop: '-16px'}}
              >
                <img src={bookmark} alt="bm" />
              </div>
              {/* <BookmarkIcon fontSize="large" style={bookmark} /> */}
              <div className="last-section-content">
                <p className="desc-text">
                  <span className="bold">
                    Tintash relished the opportunity to partner with an established industrial
                    player.
                  </span>
                  <br />
                  BBQ Guru is a renowned provider of BBQ equipment and synonymous with family,
                  community and convenience. The company had an associated app and hardware to
                  regulate the BBQ for best performance.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container tintash-sign">
          <div className="row">
            <div className="col-12">
               <div className='tin-text'> Created and Developed by</div>
               <div className='tin-img'> <img src={tinLogo} alt="bm" /></div>
               </div>
          </div>
              </section>
              
      </>
    );
  }
}

export default BbqGuru;
